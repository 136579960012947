import React from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'
import { StaticImage } from "gatsby-plugin-image"

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.boldWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    }
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
  i {
    font-style: italic;
    font-size: 0.8rem;
  }
`

const PoppyPage = ({ data }) => {
  return (
    <Container>
      <SEO title="Poppy: Interactive Choreomusicology" description="Poppy is an interactive platform that uses motion capture and machine learning to create an interface for improvisational dialogue between one or more dancers and an adaptive musical score." />
      <Title>Poppy</Title>
      <Content>
        <p>
          <i>
            "We have a whole lot of intelligence concentrated on interpreting vision of course. But, we probably have even more concentrated on interpreting what our body feels and what it does…We might be able to access this other level of effective human intelligence and understanding, that peaks out once in a while with a great athlete or with somebody playing jazz piano…See that’s very exciting because it’s defined in terms of people rather than in terms of the technology.”
          </i>
          — Jaron Lanier
        </p>
        <p>
          Poppy is an interactive platform that uses motion capture and machine learning to create an interface for improvisational dialogue between one or more dancers and an adaptive musical score.
        </p>
        <p><h2><a href='/rafael-sanchez/'>Working with dancer Rafael Sanchez to refine Poppy</a></h2></p>
        <p><h2><a href='/poppy-demo/'>Poppy demo for CultureHub</a></h2></p>
        <StaticImage src="../images/sensors.png" alt="poppy v1 using notch sensors" />
      </Content>
    </Container>
  )
}

export default PoppyPage
